import { EQueryParamMap } from '~/types/filters'
import { useBannersStore } from '~/stores/bannerStore'
import { EBannerRoutes } from '~/types/cms/contentModelEnum'

export const useBannerComposition = (routeName: EBannerRoutes) => {
  const bannerStore = useBannersStore()
  bannerStore.fetchPerRoute(routeName)

  const route = useRoute()
  watch(
    [
      () => route.query[EQueryParamMap.models],
      () => route.query[EQueryParamMap.fuels],
      () => route.params.condition,
      () => route.params.type
    ],
    () => {
      bannerStore.fetchPerRoute(routeName)
    }
  )
}
