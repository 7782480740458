import { ERoutes } from '~/types/routes'

export type EBannerRoutes = Extract<
  ERoutes,
  ERoutes.Results | ERoutes.Homepage | ERoutes.Vehicle
>

export enum ECmsSectionType {
  HERO_IMAGES = 'heroImages',
  REFINED_SEARCH = 'refinedSearch',
  CURATED_SEARCH = 'curatedSearch',
  BUDGET_SEARCH = 'budgetSearch',
  MODEL_SEARCH = 'modelSearch',
  PAGES_CONTENT_BLOCK = 'pagesContentBlock',
  PROMO_OFFER = 'promoOffer',
  HOW_IT_WORKS = 'howItWorks',
  OFFERS = 'offers',
  TRENDING = 'trendingItems',
  ACTION_BANNER = 'actionBanner',
  SIMILAR_MODELS = 'similarModels',
  INTERIOR_360 = '360Interior',
  CALCULATORS = 'calculators',
  SPOTLIGHT_CARS = 'resultsOnLanding',
  AWARD = 'awards',
  FOOTER_IMAGE = 'footerImage',
  POPULAR_MODELS = 'popularModels',
  OTHER = 'other'
}

export enum ECmsCrossSellTileFilter {
  colours = 'colours',
  transmissions = 'transmissions',
  deliveryTime = 'deliveryTime',
  fuels = 'fuels',
  engines = 'engines',
  upholsteries = 'upholsteries',
  lines = 'lines',
  packages = 'packages',
  age = 'age',
  emission = 'emission',
  mileage = 'mileage',
  keywords = 'keywords',
  hasOffer = 'hasOffer',
  excludeOutOfStock = 'excludeOutOfStock'
}

export enum ECmsFeatureIcon {
  Sideshot = 'Sideshot',
  Bucket = 'Bucket',
  Fuel = 'Fuel',
  Model = 'Model',
  Trans = 'Trans'
}

export type TCmsFilterModelId =
  | '7'
  | '8'
  | '10'
  | '13'
  | '20'
  | '30'
  | '9'
  | '16'
  | '31'
  | '68'
  | '37'
  | '63'
  | '36'
  | '1'
  | '2'
  | '89'
  | '5'
  | '15'
  | '17'
  | '23'
  | '98'
  | '14'
  | '21'
  | '25'
  | '28'
  | '96'
  | '11'
  | '18'
  | '27'
  | '29'
  | '87'
  | '103'
  | '104'
  | '105'
  | '106'
  | '107'
  | '108'

export enum ECmsFilterBodyStyle {
  Hatchback = 'Hatchback',
  Saloon = 'Saloon',
  Estate = 'Estate',
  ShootingBrake = 'Shooting Brake',
  SUV = 'SUV',
  Coupé = 'Coupé',
  Cabriolet = 'Cabriolet',
  Roadster = 'Roadster',
  MPV = 'MPV'
}
