import {
  CmsSectionOfDynamicHomepage,
  CmsPopularModels,
  CmsHeroImages,
  CmsCuratedSearch,
  CmsPagesContentBlock,
  CmsShortContentBlock,
  CmsModelImages,
  CmsOffer,
  CmsTrendingItem,
  CmsButton,
  CmsSectionOfDynamicHomepageContent,
  CmsCalculatorItem,
  CmsAward,
  CmsResultsOnLanding,
  CmsImages,
  CmsPromotionalOffer
} from '~/types/cms/contentModel'
import { ECmsSectionType } from '~/types/cms/contentModelEnum'

// not used
// | CmsFeatures
// | CmsFilter
// | CmsPromoImages

type sectionType<T extends CmsSectionOfDynamicHomepageContent | undefined> =
  Omit<CmsSectionOfDynamicHomepage, 'content'> & {
    content: T[]
  }

export type CmsDynamicSectionBySectionType<T extends ECmsSectionType> =
  T extends ECmsSectionType.HERO_IMAGES
    ? sectionType<CmsHeroImages>
    : T extends ECmsSectionType.REFINED_SEARCH
      ? sectionType<undefined>
      : T extends ECmsSectionType.CURATED_SEARCH
        ? sectionType<CmsCuratedSearch>
        : T extends ECmsSectionType.BUDGET_SEARCH
          ? sectionType<undefined>
          : T extends ECmsSectionType.MODEL_SEARCH
            ? sectionType<undefined>
            : T extends ECmsSectionType.PAGES_CONTENT_BLOCK
              ? sectionType<CmsPagesContentBlock>
              : T extends ECmsSectionType.PROMO_OFFER
                ? sectionType<CmsPromotionalOffer> // **
                : T extends ECmsSectionType.HOW_IT_WORKS
                  ? sectionType<CmsShortContentBlock | CmsModelImages>
                  : T extends ECmsSectionType.OFFERS
                    ? sectionType<CmsOffer>
                    : T extends ECmsSectionType.TRENDING
                      ? sectionType<CmsTrendingItem>
                      : T extends ECmsSectionType.ACTION_BANNER
                        ? sectionType<CmsButton>
                        : T extends ECmsSectionType.SIMILAR_MODELS
                          ? sectionType<undefined> // **
                          : T extends ECmsSectionType.INTERIOR_360
                            ? sectionType<CmsModelImages>
                            : T extends ECmsSectionType.CALCULATORS
                              ? sectionType<CmsCalculatorItem>
                              : T extends ECmsSectionType.SPOTLIGHT_CARS
                                ? sectionType<CmsResultsOnLanding>
                                : T extends ECmsSectionType.AWARD
                                  ? sectionType<CmsAward>
                                  : T extends ECmsSectionType.FOOTER_IMAGE
                                    ? sectionType<CmsImages>
                                    : T extends ECmsSectionType.POPULAR_MODELS
                                      ? sectionType<CmsPopularModels>
                                      : CmsSectionOfDynamicHomepage

export const findHomeSection = <T extends ECmsSectionType>(
  sections: CmsSectionOfDynamicHomepage[],
  sectionTypeAttr: T
): CmsDynamicSectionBySectionType<T> | undefined => {
  return sections.find(({ sectionType }) => sectionType === sectionTypeAttr) as
    | CmsDynamicSectionBySectionType<T>
    | undefined
}
